	class CustomMenu extends HTMLElement{
    constructor(){
    	super();
      	this.elements = {
          mainElements: [...this.querySelectorAll('.main-items .site-nav__dropdown-link')],
          childElements: [...this.querySelectorAll('.hc-group')],
         
        };
        this.childClassName = 'hc-visible';
        this.mainClassName = 'current_element';
        this.elements.mainElements.forEach(this.loopElements.bind(this));
    }
    loopElements = (element) => {
        element.addEventListener('mouseover', this.handleHover.bind(this));
    };
    handleHover = (evt) => {
      let { child } = evt.target.dataset;
      evt.target.classList.add('current_element');
      let active = this.findActive({ child });
      active.classList.add('hc-visible');
      this.disableElements({ 
        nodeElements: this.elements.childElements, 
        toCompare: child, 
        className:this.childClassName,
        type:'child'
      });
      this.disableElements({ 
        nodeElements: this.elements.mainElements, 
        toCompare: evt.target, 
        className:this.mainClassName,
        type:'main'
      });
    };
    findActive = ({ child }) => this.elements.childElements.find((element)=>element.dataset.group === child);
  	disableElements = ({ nodeElements, toCompare, className, type }) => {
      let disabledElements = nodeElements.filter((element) => {
        return this.getOtherElements({
          element: type === 'child'?element.dataset.group:element, 
          toCompare
        });
      });
      this.removeClass({ elements: disabledElements, className });
  	};
    getOtherElements = ({ element, toCompare }) => element !== toCompare;
  	removeClass = ({ elements, className }) => elements.map( element => element.classList.remove(className));                                                                                                 
  }
  customElements.define('custom-element', CustomMenu);